<template>
  <div class="h-full cust_card p-2 mt-2 ">
    <div class="heading-3 text-text1" :class="mobileView ? 'pb-4' : 'pb-4'">● Campaign: {{campaignName}}
      <Button class="" @buttonAction="$router.push({name: 'Leads',query: {campaignMasterId: parseInt($route.params.campaignMasterId)}})" :disabled="false" :btnSize="!mobileView ? 'medium' : 'small'" :textColor="'white'" :btnColor="'primary'" :btnText="`${leadReceiveCount} ${leadReceiveCount > 1 ? 'Leads' : 'Lead'}`"/>
    </div>
    <div class="bottom_space rounded py-2 pt-0 h-full bg-white">
      <div class="">
        <div class="mb-2 md:grid grid-cols-2">
          <div class="flex items-center">
              <div class="max-w-max mr-4 flex items-center">
                <div class="select_button text-sm text-text2">
                  <SimpleDropdown :dropdownList="items" @callApiAsperSelectValue1="getLeaveDataAsPerPagination1"/>
                  </div>
                </div>
                <div>
                  <SearchTextInput :class="mobileView ? '' : 'w-72'" class="" @searchCall="searchCall" :placeholderText="mobileView ? 'Search...' :`Total ${totalEmpCount} contact${totalEmpCount > 1 ? 's' : ''} found`"/>
                </div>
            </div>
          <div>
            <div class="flex flex-wrap items-baseline justify-end py-2">
              <div class="" v-if="mobileView">
                <div v-if="this.organizationList.length > 0" @click.stop="isOpenValue()" :class="openSmallFilter ? 'bg-gray4 text-white' : 'bg-primary text-white'" class="px-5 py-1.5 heading-6 rounded-xl mr-2">{{openSmallFilter ? 'Close' :'Sort By'}}</div>
              </div>
            </div>
          </div>
        </div>
        <div  v-if="openSmallFilter && mobileView" class="h-16  bg-white border border-gray2 rounded-md card flex items-center mb-2">
          <div class="pl-4">
              <Dropdown
                :inputext="selectListValue" 
                :inputId="'phoneNoVal'"
                :lableText="''"
                :placholderText="`Select Phone #`"
                :autoFocus="false"
                :showArrow="true"
                :setReadOnly="false"
                :searchCallApi="true"
                :showPlus="false"
                :keyName="'title'"
                :listId="'sortName'"
                :items="titleObj"
                @selectNewForVal="selectNumber"
              />
          </div>
          <div class="px-6">
            <div class="bg-primary text-white px-3 py-0.5 rounded-xl">
              <span @click="shortFilter('ASC')" v-if="filterObject.sortOrder === 'DESC'"><i class="fa-solid fa-caret-down"></i></span>
              <p @click="shortFilter('DESC')" v-if="filterObject.sortOrder === 'ASC'"><i class="fa-solid fa-caret-up"></i></p>
            </div>
          </div>
        </div>
      </div>
      <div>
      <div class="w-full">
        <div class="progress-bar" v-if="searchLoader">
          <div class="progress-bar-value"></div>
        </div>
      </div>
      <div style="overflow-y: auto; white-space: nowrap;" class="table_containder " v-if="!mobileView">
        <div class="text-text2  min-w-min heaer_row border border-gray1 font-bold flex heading-5 px-3 py-3 items-center">
          <div class="email_col flex items-center p-1">
            <span class="cursor-pointer" :class="titleObj[0].isSel ? 'text-primary' : ''" @click="sortByColumn(0)">{{titleObj[0].title}} </span>
            <img v-if="titleObj[0].sortVal === 'DESC'" src="@/assets/images/svg/DESC.svg" /> 
            <img v-if="titleObj[0].sortVal === 'ASC'" src="@/assets/images/svg/ASC.svg" /> 
          </div>
          <div class="email_col  flex items-center p-1">
            <span class="cursor-pointer" :class="titleObj[1].isSel ? 'text-primary' : ''" @click="sortByColumn(1)">{{titleObj[1].title}}</span>
            <img class="pl-1" v-if="titleObj[1].sortVal === 'DESC'" src="@/assets/images/svg/DESC.svg" /> 
            <img class="pl-1" v-if="titleObj[1].sortVal === 'ASC'" src="@/assets/images/svg/ASC.svg" /> 
          </div>
          <div class="email_col  flex items-center p-1">
            <span class="cursor-pointer" :class="titleObj[2].isSel ? 'text-primary' : ''" @click="sortByColumn(2)">{{titleObj[2].title}}</span>
            <img class="pl-1" v-if="titleObj[2].sortVal === 'DESC'" src="@/assets/images/svg/DESC.svg" /> 
            <img class="pl-1" v-if="titleObj[2].sortVal === 'ASC'" src="@/assets/images/svg/ASC.svg" /> 
          </div>
          <div class="last_msg  flex items-center p-1">
            <span class="cursor-pointer" :class="titleObj[3].isSel ? 'text-primary' : ''" @click="sortByColumn(3)">{{titleObj[3].title}}</span>
            <img class="pl-1" v-if="titleObj[3].sortVal === 'DESC'" src="@/assets/images/svg/DESC.svg" /> 
            <img class="pl-1" v-if="titleObj[3].sortVal === 'ASC'" src="@/assets/images/svg/ASC.svg" /> 
          </div>
          <div class="org_name  flex items-center p-1">
            <span class="cursor-pointer" :class="titleObj[4].isSel ? 'text-primary' : ''" @click="sortByColumn(4)">{{titleObj[4].title}}</span>
            <img class="pl-1" v-if="titleObj[4].sortVal === 'DESC'" src="@/assets/images/svg/DESC.svg" /> 
            <img class="pl-1" v-if="titleObj[4].sortVal === 'ASC'" src="@/assets/images/svg/ASC.svg" /> 
          </div>
          <div class="date_col  flex items-center p-1">
            <span class="" :class="titleObj[5].isSel ? '' : ''">{{titleObj[5].title}}</span>
          </div>
        </div>
        <div v-for="(data, index) in organizationList" :key="index" class="min-w-min group relative   flex border-r border-l border-b border-gray1  bg-white  text-sm px-3 py-2 items-center hover:bg-gray-50  cursor-pointer" @click.stop="contactDetail(data, 'same')">
          <div class="email_col p-1" >
            <span class="heading-5 text-text2" v-if="data.firstName !== ''">{{data.firstName}}</span>
            <span class="" v-else>--</span>
          </div>
          <div class="email_col p-1" >
            <span class="heading-5 text-text2 overFlowParaA" v-if="data.lastName !== ''">{{data.lastName}}</span>
            <span class="" v-else>--</span>
          </div>
          <div class="email_col p-1" >
            <span class="heading-5 text-text2 overFlowParaA" v-if="data.phoneNumber !== ''">{{data.phoneNumber | phone}}</span>
            <span class="" v-else>--</span>
          </div>
          <div class="last_msg p-1" >
            <span class="heading-5 text-text2 overFlowParaA" v-if="data.lastMessageSent !== ''">{{data.lastMessageSent}}</span>
            <span class="" v-else>--</span>
          </div>
          <div class="org_name p-1">
            <span class="heading-5 text-text2" v-if="data.lastActivity !== ''">{{data.lastActivity | dateTimeWithName}}</span>
            <span class="" v-else>--</span>
          </div>
          <div class="date_col p-1">
            <span class="heading-5 text-text2">{{data.totalSentCount}}</span>
          </div>
          <div class="pl-2 text-text2 hover:text-primary heading-4 transition absolute right-7" @click.stop="contactDetail(data, 'new')"><i class="fa-solid fa-arrow-up-right-from-square"></i></div>
        </div>
      </div>
      <div style="white-space: nowrap;" class="table_containder grid md:grid-cols-2 gap-2" v-else >
        <div v-for="(data, index) in organizationList" :key="index" class="min-w-min group cust_card  relative border-r border-l border-b border-gray1  bg-white  text-sm px-3 py-2 items-center hover:bg-gray-50  cursor-pointer" @click.stop="contactDetail(data, 'same')">
          <div class="p-1">
            <div class="pl-2 text-primary heading-5 transition absolute right-4" @click.stop="contactDetail(data, 'new')"><i class="fa-solid fa-arrow-up-right-from-square"></i></div>
            <p class="mb-0" style="display: flex;">
              <span class="text-text2 heading-6 font-semibold labelwidth2"><p class="mb-0">First Name</p></span>
              <span><p class="mb-0 dotwidth">:</p></span>
              <span class="text-text1 heading-6 whitespace-pre-line" v-if="data.firstName !== ''">{{data.firstName}}</span>
              <span class="text-text1 heading-6" v-else>--</span>
            </p>
            <p class="mb-0" style="display: flex;">
              <span class="text-text2 heading-6 font-semibold labelwidth2"><p class="mb-0">Last Name</p></span>
              <span><p class="mb-0 dotwidth">:</p></span>
              <span class="text-text1 heading-6 whitespace-pre-line" v-if="data.lastName !== ''">{{data.lastName}}</span>
              <span class="text-text1 heading-6" v-else>--</span>
            </p>
            <p class="mb-0" style="display: flex;">
              <span class="text-text2 heading-6 font-semibold labelwidth2"><p class="mb-0">Cell Number</p></span>
              <span><p class="mb-0 dotwidth">:</p></span>
              <span class="text-text1 heading-6 whitespace-pre-line" v-if="data.phoneNumber !== ''">{{data.phoneNumber | phone}}</span>
              <span class="text-text1 heading-6" v-else>--</span>
            </p>
            <p class="mb-0" style="display: flex;">
              <span class="text-text2 heading-6 font-semibold labelwidth2"><p class="mb-0">Last Message</p></span>
              <span><p class="mb-0 dotwidth">:</p></span>
              <span class="text-text1 heading-6 whitespace-pre-line" v-if="data.lastMessageSent !== ''">{{data.lastMessageSent}}</span>
              <span class="text-text1 heading-6" v-else>--</span>
            </p>
            <p class="mb-0" style="display: flex;">
              <span class="text-text2 heading-6 font-semibold labelwidth2"><p class="mb-0">Last Activity On</p></span>
              <span><p class="mb-0 dotwidth">:</p></span>
              <span class="text-text1 heading-6 whitespace-pre-line" v-if="data.lastActivity !== ''">{{data.lastActivity | dateTimeWithName}}</span>
              <span class="text-text1 heading-6" v-else>--</span>
            </p>
            <p class="mb-0" style="display: flex;">
              <span class="text-text2 heading-6 font-semibold labelwidth2"><p class="mb-0">Message Sent</p></span>
              <span><p class="mb-0 dotwidth">:</p></span>
              <span class="text-text1 heading-6 whitespace-pre-line">{{data.totalSentCount}}</span>
            </p>
          </div>
        </div>
      </div>
      <div class="layout my-2" v-if="organizationList.length === 0">
        <div class="flex p-3 text-sm text-primary bg-blue-100 rounded-lg  items-center" role="alert">
          <svg aria-hidden="true" class="flex-shrink-0 inline w-6 h-6 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
          <span class="sr-only">Info</span>
          <div>
            <p class=" font-medium heading-4">No record found.</p>
          </div>
        </div>
      </div>
    </div>
    <div class="pt-2">
      <Pagination
      :totalDataCount="totalEmpCount"
      :resetPagination="resetPaginationValues"
      @callApiAsPerPagination="getCustomerDataFromPagination"/>
    </div>
  </div>
</div>
</template>
<script>
import Dropdown from '@/View/components/dropdown.vue'
import SearchTextInput from '@/View/components/searchTextInput.vue'
import ADMINAPI from '@/View/Admin/api/Admin.js'
import SimpleDropdown from '@/View/components/simpleDropdown.vue'
import Pagination from '@/View/components/pagination.vue'
import deboucneMixin from '@/mixins/debounce.js'
import Button from '@/View/components/globalButton.vue'
export default {
  components: {
    Dropdown,
    SearchTextInput,
    Pagination,
    SimpleDropdown,
    Button,
  },
  mixins: [deboucneMixin],
  props: ['orgId'],
  data () {
    return {
      campaignName: '',
      leadReceiveCount: 0,
      importPopup: false,
      openSmallFilter: false,
      mobileView: false,
      searchLoader: false,
      resetPaginationValues: false,
      searchForCustomer: '',
      titleObj: [
        {id: 0, title: 'First Name', icon: '', sortName: 'firstName', sortVal: '', isSel: false},
        {id: 1, title: 'Last Name', icon: '', sortName: 'lastName', sortVal: '', isSel: false},
        {id: 2, title: 'Cell Number', icon: '', sortName: 'phoneNumber', sortVal: '', isSel: false},
        {id: 4, title: 'Last Message', icon: '', sortName: 'lastMessageSent', sortVal: '', isSel: false},
        {id: 5, title: 'Last Activity On', icon: 'fas fa-sort-amount-down', sortName: 'lastActivity', sortVal: 'DESC', isSel: true},
        {id: 6, title: 'Message sent', icon: '', sortName: 'campaignName', sortVal: '', isSel: false},
    ],
      filterObject: {
        start: 0,
        length: 20,
        searchVal: '',
        sortBy: 'lastActivity',
        sortOrder: 'DESC',
        organizationId: 0,
      },
      totalEmpCount: 0,
      paginationLength: 1,
      filterCol: 'text-gray-400',
      page: 1,
      items: [20, 25, 30, 50, 100],
      limitResData: 20,
      paginationData: 20,
      organizationList: [],
      selectListValue: 'Last Activity'
    }
  },
  created() {
    window.addEventListener("resize", this.resizeWindowHandler);
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeWindowHandler);
  },
  mounted () {
    this.resizeWindowHandler();
    document.title = 'List List'
    this.getContactListDeboucne = this.debounce(function () {
      this.resetPaginationValues = true
      this.searchLoader = true
      console.log('debounde')
    }, 500)
    this.getDripMessageSentLog()
  },
  watch: {
    titleObj: {
      handler () {
        console.log('titleObj 123', this.titleObj)
      }
    },
    paginationData: {
      handler () {
        this.filterObject.length = this.paginationData
        this.showlistLoader = true
        this.getDripMessageSentLog(this.showlistLoader)
      },
      deep: true
    }
  },
  methods: {
    selectNumber (data) {
      this.selectListValue = data.title
      this.sortByColumn(data.id)
    },
    searchCall (data) {
      this.searchForCustomer = data
      this.getContactListDeboucne()
    },
    isOpenValue () {
      if (this.organizationList.length > 0) {
        this.openSmallFilter = !this.openSmallFilter
      }
    },
    onClickOutside () {
      console.log('Close value', this.isOpen)
      this.openSmallFilter = false
    },
    resizeWindowHandler() {
      if (window.innerWidth < 1024) {
        this.mobileView = true;
      } else {
        this.mobileView = false;
      }
    },
    getCustomerDataFromPagination (data) {
      this.filterObject.start = data.offset
      this.filterObject.length = data.limit
      let showlistLoader = false
      if (data.offset !== 0) {
        showlistLoader = true
      }
      this.getDripMessageSentLog(showlistLoader)
    },
    shortFilter (index) {
      this.filterObject.sortOrder = index
      this.getDripMessageSentLog(true)
    },
    sortByColumn (index) {
      for (let i = 0; i < this.titleObj.length; i++) {
        if (i === index) {
          this.selectListValue = this.titleObj[i].title
          if (this.titleObj[i].icon === '') {
            this.titleObj[i].icon = 'fas fa-sort-amount-down'
            this.titleObj[i].sortVal = 'DESC'
            this.filterObject.sortOrder = 'DESC'
            this.titleObj[i].isSel = true
          } else if (this.titleObj[i].icon === 'fas fa-sort-amount-down') {
            this.titleObj[i].icon = 'fas fa-sort-amount-up'
            this.titleObj[i].sortVal = 'ASC'
            this.filterObject.sortOrder = 'ASC'
            this.titleObj[i].isSel = true
          } else {
            this.titleObj[i].icon = 'fas fa-sort-amount-down'
            this.filterObject.sortOrder = 'DESC'
            this.titleObj[i].sortVal = 'DESC'
            this.titleObj[i].isSel = true
          }
      } else {
          this.titleObj[i].icon = ''
          this.titleObj[i].sortVal = ''
          this.titleObj[i].isSel = false
        }
      }
      this.filterObject.sortBy = this.titleObj[index].sortName
      this.getDripMessageSentLog(true)
    },
    toTop () {
      this.$vuetify.goTo(0)
    },
    contactDetail (data, where) {
      if (where === 'same') {
        this.$router.push({name: 'contactDetail', params: {leadId: data.leadId}, query: {campaignMasterId: data.campaignMasterId}})
      } else {
        let url = this.$router.resolve({name: 'contactDetail', params: {leadId: data.leadId}, query: {campaignMasterId: data.campaignMasterId}})
        window.open(url.href, '_blank')
      }
    },
    getLeaveDataAsPerPagination1 (data) {
      this.filterObject.length = data
      this.getDripMessageSentLog()
    },
    getDripMessageSentLog () {
      console.log('this.routing', this.orgId)
      if (this.searchForCustomer === '') {
        this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      }
      ADMINAPI.GetDripMessageSentLog(
        this.filterObject.start,
        this.filterObject.length,
        this.searchForCustomer,
        this.filterObject.sortBy,
        this.filterObject.sortOrder,
        parseInt(this.$route.params.campaignMasterId),
        response => {
          console.log("response", response.Data)
          this.resetPaginationValues = false
          this.campaignName = response.Data.campaignName
          this.leadReceiveCount = response.Data.leadReceiveCount
          this.organizationList = response.Data.tableRow === null ? [] : response.Data.tableRow
          this.totalEmpCount = response.Data.count
          this.searchLoader = false
          if (this.organizationList.length === 0) {
            this.openSmallFilter = false
          }
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        error => {
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    }
  },
  beforeDestroy () {
  }
}
</script>
<style scoped>
.table_containder {
  padding: 1px;
}

.col_name {
  min-width: 320px !important;
  width: 320px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.last_msg {
  min-width: 370px !important;
  width: 370px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.org_name {
  min-width: 170px !important;
  width: 280px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.email_col {
  min-width: 120px !important;
  width: 200px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.date_col {
  min-width: 160px !important;
  width: 210px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}

.search_customer_box {
  min-width: 335px !important;
  max-width: 335px !important;
}

.progress-bar {
  height: 2px;
  background-color: rgb(18,149,186);
  width: 100%;
  overflow: hidden;
}

.progress-bar-value {
  width: 100%;
  height: 100%;
  background-color: rgb(121, 200, 221);
  animation: indeterminateAnimation 2s infinite linear;
  transform-origin: 0% 50%;
}

@keyframes indeterminateAnimation {
  0% {
    transform:  translateX(0) scaleX(0);
  }
  40% {
    transform:  translateX(0) scaleX(0.4);
  }
  100% {
    transform:  translateX(100%) scaleX(0.5);
  }
}
::placeholder {
  font-size: 16px;
  color: #494949;
}
.labelwidth2 {
  width: 110px;
  min-width: 110px;
}
</style>
